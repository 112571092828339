import React from 'react'
import { useTranslations } from 'next-intl'
import { twJoin, twMerge } from 'tailwind-merge'

import { Currency } from '@/components/currency'
import { CartIcon } from '@/components/icons/svg'
import { useCartContext, useStoreContext } from '@/providers'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

interface HeaderCartButtonProps {
  href: string
}

const MAX_CART_COUNT_DISPLAYED = 999

export const HeaderCartButton = ({ href }: HeaderCartButtonProps) => {
  const t = useTranslations('Header')

  const {
    storeConfig: { currency },
  } = useStoreContext()
  const { cartItems, hasItems, isCartRefetching } = useCartContext()
  const totalSum = cartItems.reduce((acc, item) => {
    return acc + (item?.prices?.row_total_including_tax?.value || 0)
  }, 0)

  const cartItemsCount = cartItems.reduce((accumulator, item) => {
    return accumulator + item.quantity
  }, 0)

  const showCount = hasItems || isCartRefetching
  const isItemsCountTooLarge = cartItemsCount > MAX_CART_COUNT_DISPLAYED

  return (
    <Link
      className="flex h-12 w-fit items-center hover:bg-transparent gap-x-2"
      href={href}
      aria-label={t('cart')}
      isExternal
    >
      <div
        className="relative flex items-center data-[bubble=true]:w-[46px]"
        data-bubble={showCount || isCartRefetching}
      >
        <CartIcon className="h-7 w-7 md:h-6 md:w-8" />
        {showCount && (
          <div
            className={twMerge(
              'flex justify-center items-center',
              'absolute -bottom-1 md:right-0 right-[3.5px]',
              'bg-secondary rounded-full',
              isCartRefetching && 'bg-secondary-disabled',
              isItemsCountTooLarge ? 'h-7 w-7' : 'h-6 w-6',
            )}
            title={`${cartItemsCount}`}
          >
            <span
              data-test={selectors.PDP.cartCount}
              className="text-white text-xs"
            >
              {isItemsCountTooLarge ? MAX_CART_COUNT_DISPLAYED : cartItemsCount}
              {isItemsCountTooLarge && <sup>+</sup>}
            </span>
          </div>
        )}
        {isCartRefetching && (
          <div
            className={twJoin(
              'absolute -bottom-2 md:-bottom-2 -right-[1px] md:-right-1',
              'h-8 w-8',
              'rounded-full',
              'animate-[spin_0.75s_linear_infinite]',
              'border-transparent border-[3px] border-t-secondary',
            )}
          />
        )}
      </div>
      {totalSum > 0 && (
        <Currency
          className="hidden lg:flex left-full whitespace-nowrap font-bold text-sm"
          currency={currency}
          price={totalSum}
        />
      )}
    </Link>
  )
}
