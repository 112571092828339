'use client'

import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'

interface HeaderContext {
  state: {
    isMenuOpen: boolean
    isMobileSearch: boolean
  }
  actions: {
    setIsMenuOpen: (isMenuOpen: boolean) => void
    setIsMobileSearch: (isMobileSearch: boolean) => void
  }
}

export const HeaderContext = createContext<HeaderContext>({
  state: { isMenuOpen: false, isMobileSearch: false },
} as HeaderContext)

export const useHeaderContext = () => useContext(HeaderContext)

export const HeaderContextProvider = ({ children }: PropsWithChildren) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const [isMobileSearch, setIsMobileSearch] = useState(false)

  const state: HeaderContext['state'] = useMemo(
    () => ({
      isMenuOpen,
      isMobileSearch,
    }),
    [isMenuOpen, isMobileSearch],
  )

  const actions: HeaderContext['actions'] = useMemo(
    () => ({
      setIsMenuOpen,
      setIsMobileSearch,
    }),
    [],
  )

  const value = useMemo(
    () => ({
      state,
      actions,
    }),
    [state, actions],
  )

  return (
    <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
  )
}
