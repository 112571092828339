import * as React from 'react'
import { InputHTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

const variants = cva(
  [
    'h-field',
    'text-sm',
    'leading-7',
    'px-3',
    'w-full',
    'transition',
    'rounded-none',
    'hover:border-[#757779]',
    'focus:rounded-none',
    'focus:shadow-md',
    'focus:shadow-grey-200',
    'disabled:cursor-not-allowed',
    'disabled:opacity-50',
    'outline-none',
  ],
  {
    variants: {
      variant: {
        normal: ['border-2', 'border-black'],
        error: ['border-2', 'border-red-500'],
        autocomplete: [
          'border-2',
          'border-black',
          'text-sm',
          'focus:shadow-none',
          'focus:border-black',
        ],
      },
      disabled: {
        true: ['hover:border-auto'],
      },
    },
    defaultVariants: {
      variant: 'normal',
    },
  },
)

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  variant?: 'normal' | 'error' | 'autocomplete'
  required?: boolean
  min?: number | string
  max?: number | string
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ variant, required, className, type, ...props }, ref) => {
    return (
      <input
        className={twMerge(
          variants({ variant, disabled: props.disabled, className }),
        )}
        type={type || 'text'}
        ref={ref}
        required={required}
        aria-required={required}
        {...props}
      />
    )
  },
)

Input.displayName = 'Input'
export { Input }
