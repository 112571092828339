import md5 from 'md5'

import { STORE_CODE_RECORD } from '@/common/constants'

export const localhostPort = 3000
export const localhostUrl = `http://localhost:${localhostPort}`
export const rootPath = '/'
export const locales = Object.values(STORE_CODE_RECORD).map(
  (record) => record.locale,
)
export const defaultCustomerSegment = md5('General')
