import ReactDOM from 'react-dom'
import React, { Children, cloneElement, ReactElement } from 'react'

export type ModalBodyProps = {
  isOpen: boolean
  enterAnimation?: string
  leaveAnimation?: string
  children: ReactElement | ReactElement[]
  onClose: () => void
}

export const ModalBody = ({ children, ...childrenProps }: ModalBodyProps) => {
  return ReactDOM.createPortal(
    <div className="fixed inset-0 flex items-start justify-center z-40">
      {Children.map(children, (child) => cloneElement(child, childrenProps))}
    </div>,
    document.body,
  )
}
