'use client'
import { FormEvent, useState } from 'react'
import { useTranslations } from 'next-intl'

import {
  GraphQlErrorsResponse,
  SubscribeEmailToNewsletterMutation,
  useSubscribeEmailToNewsletterMutation,
} from '@/api'
import { useValidateInput } from '@/common/hooks/use-validate-input'
import { Button } from '@/components/button'
import { QuestionMarkRound } from '@/components/icons/svg'
import { Input } from '@/components/input'
import { Tooltip } from '@/components/tooltip'
import { useFlashMessagesContext } from '@/providers'
import { validateEmail } from '@/utils'
import { selectors } from '@/common/constants/selectors-constants'

export const SubscriberEmail = () => {
  const t = useTranslations('Footer')
  const { addFlashMessage } = useFlashMessagesContext()

  const [isFormSubmitting, setIsFormSubmitting] = useState(false)

  const { mutateAsync: subscribeEmailMutateAsync } =
    useSubscribeEmailToNewsletterMutation()

  const {
    value: enteredEmail,
    isEmpty: emailInputIsEmpty,
    valueChangeHandler: emailChangeHandler,
    inputBlurHandler: emailBlurHandler,
    reset: emailReset,
  } = useValidateInput(validateEmail)

  const submitFormHandler = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    setIsFormSubmitting(true)
    try {
      const data: SubscribeEmailToNewsletterMutation & GraphQlErrorsResponse =
        await subscribeEmailMutateAsync({
          email: enteredEmail,
        })
      if (data.errors) {
        throw new Error(data.errors[0].extensions?.category)
      }

      addFlashMessage({
        severity: 'success',
        text: t('subscribe.success'),
      })
    } catch (error) {
      switch (error.message) {
        case 'graphql-already-exists':
          addFlashMessage({
            severity: 'success',
            text: t('subscribe.error.alreadySubscribed'),
          })
          break
        case 'graphql-input':
          addFlashMessage({
            severity: 'error',
            text: t('subscribe.error.invalidEmail'),
          })
          break
        default:
          addFlashMessage({
            severity: 'error',
            text: t('subscribe.error.invalidEmail'),
          })
          break
      }
    } finally {
      emailReset()
      setIsFormSubmitting(false)
    }
  }

  const buttonDisabled = isFormSubmitting || emailInputIsEmpty

  return (
    <div className="w-full pb-5 pt-1">
      <form onSubmit={submitFormHandler}>
        <div
          data-test={selectors.common.footer.emailSubscription}
          className="flex w-full flex-col sm:gap-2 gap-1 sm:justify-center sm:flex-row"
        >
          <label
            htmlFor="subscriberEmail"
            className="text-left font-bold leading-4 sm:-my-0.5 sm:text-right"
          >
            <span className="inline sm:block">
              {t('subscribe.label.goodEnergy')}{' '}
            </span>
            <span>{t('subscribe.label.yourEmail')}</span>{' '}
          </label>

          <div className="flex sm:gap-2 gap-1 lg:min-w-[600px]">
            <div className="relative w-full flex-1">
              <Input
                id="subscriberEmail"
                type="email"
                name="newsletter"
                placeholder={t('subscribe.input.insertEmail')}
                className="!h-[30px] w-full border-none text-sm text-black sm:min-w-[220px] md:min-w-[300px]"
                required
                disabled={isFormSubmitting}
                value={enteredEmail}
                onBlur={emailBlurHandler}
                onChange={emailChangeHandler}
              />
              <div className="absolute hidden right-2 top-1 sm:block z-10">
                <Tooltip
                  text={
                    <div className="w-56 text-black">
                      {t('subscribe.tooltip')}
                    </div>
                  }
                  direction="bottom"
                >
                  <QuestionMarkRound width="22px" height="22px" />
                </Tooltip>
              </div>
            </div>
            <Button
              variant="secondary"
              type="submit"
              size="sm"
              className="px-5 py-[3.5px] font-bold max-h-[30px]"
              disabled={buttonDisabled}
            >
              {t('subscribe.button')}
            </Button>
          </div>
        </div>
        <div className="block mt-0.5 sm:hidden">{t('subscribe.tooltip')}</div>
      </form>
    </div>
  )
}
