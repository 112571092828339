'use client'
import { ChangeEvent, useState } from 'react'

export const useValidateInput = (validateValue: (value: string) => boolean) => {
  const [enteredValue, setEnteredValue] = useState('')
  const [isTouched, setIsTouched] = useState(false)

  const valueIsInvalid = isTouched && !validateValue(enteredValue)
  const isEmpty = isTouched && enteredValue.trim().length === 0
  const isIncorrect = valueIsInvalid && !isEmpty

  const valueChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEnteredValue(event.target.value)
  }

  const inputBlurHandler = () => {
    setIsTouched(true)
  }

  const reset = () => {
    setEnteredValue('')
    setIsTouched(false)
  }

  return {
    value: enteredValue,
    isIncorrect,
    isEmpty,
    valueChangeHandler,
    inputBlurHandler,
    reset,
  }
}
