'use client'

import { useEffect } from 'react'

import { useStoreContext } from '@/providers'
import { BadgeEnum } from '../enums'
import { trackingConfig } from '@/common/services/tracking/tracking-config'
import '@/common/services/tracking/widgets.css'

export const HeurekaBadge = () => {
  const { storeCode } = useStoreContext()

  useEffect(() => {
    const script = document.createElement('script')

    let code = ''

    if (storeCode === 'gymbeamsk') {
      code = `_hwq = window._hwq || [];

        _hwq.push(['setKey', '${trackingConfig.HeurekaBadgeSk.apiKey}']);
        _hwq.push(['etKey', '${trackingConfig.HeurekaBadgeSk.apiKey}']);
        _hwq.push(['showWidget', '12', '10276', 'GymBeam', 'gymbeam-sk']);


        (function () {
            var ho = document.createElement('script');
            ho.type = 'text/javascript';
            ho.async = true;
            ho.src = 'https://ssl.heureka.sk/direct/i/gjs.php?n=wdgt&sak=${trackingConfig.HeurekaBadgeSk.apiKey}';
            var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);
        })();`
    } else if (storeCode === 'gymbeamcz') {
      code = ` _hwq = window._hwq || [];

            _hwq.push(['setKey', '${trackingConfig.HeurekaBadgeCz.apiKey}']);
            _hwq.push(['etKey', '${trackingConfig.HeurekaBadgeCz.apiKey}']);
            _hwq.push(['showWidget', '12', '59620', 'GymBeam', 'gymbeam-cz']);


            (function () {
                var ho = document.createElement('script');
                ho.type = 'text/javascript';
                ho.async = true;
                ho.src = 'https://ssl.heureka.cz/direct/i/gjs.php?n=wdgt&sak=${trackingConfig.HeurekaBadgeCz.apiKey}';
                var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ho, s);
            })();
        `
    }

    script.innerHTML = code
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [storeCode])

  return <div className="my-6" id={BadgeEnum.heureka}></div>
}
