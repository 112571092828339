import { STORE_CODE_RECORD } from '@/common/constants'
import { StoreCodeType } from '@/common/types'

export type TrackingConfig = Record<TrackerId, TrackingConfigEntry>

export type TrackingConfigEntry = {
  scriptSrc?: string
  apiKey?: string
  stores: StoreCodeType[]
}

export enum TrackerId {
  Dognet = 'Dognet',
  Pazaruvaj = 'Pazaruvaj',
  Kdukvh = 'Kdukvh',
  Skroutz = 'Skroutz',
  Compari = 'Compari',
  Arukereso = 'Arukereso',
  HeurekaBadgeCz = 'HeurekaBadgeCz',
  HeurekaBadgeSk = 'HeurekaBadgeSk',
  SpeedCurve = 'SpeedCurve',
  TrustPilot = 'TrustPilot',
}

export const trackingConfig: TrackingConfig = {
  [TrackerId.Dognet]: {
    // TODO: implementation is planned in separate task
    stores: [
      'gymbeamsk',
      'gymbeamcz',
      'gymbeamhu',
      'gymbeamro',
      'gymbeamhr',
      'gymbeambg',
      'gymbeampl',
      'gymbeamsi',
    ],
  },
  [TrackerId.Pazaruvaj]: {
    apiKey: '72840e7bbd1e1ed5aa14a24cb22a6be3',
    scriptSrc: 'https://static.pazaruvaj.com/widget/presenter.js',
    stores: ['gymbeambg'],
  },
  [TrackerId.Kdukvh]: {
    stores: [
      'gymbeamcz',
      'gymbeamhu',
      'gymbeamro',
      'gymbeamhr',
      'gymbeambg',
      'ua',
      'gymbeampl',
    ],
  },
  [TrackerId.Skroutz]: {
    apiKey: 'SA-9764-7750',
    scriptSrc: 'https://analytics.skroutz.gr/analytics.min.js',
    stores: ['gymbeamgr'],
  },
  [TrackerId.Compari]: {
    apiKey: '74b2168b9a95e40fdd847080acb67957',
    scriptSrc: 'https://static.compari.ro/widget/presenter.js',
    stores: ['gymbeamro'],
  },
  [TrackerId.Arukereso]: {
    apiKey: '714778024a11efb3d3009a23b4e2c698',
    scriptSrc: 'https://static.arukereso.hu/widget/presenter.js',
    stores: ['gymbeamhu'],
  },
  [TrackerId.HeurekaBadgeCz]: {
    apiKey: '97D481443D14365E41728A79D66D63AA',
    stores: ['gymbeamsk', 'gymbeamcz'],
  },
  [TrackerId.HeurekaBadgeSk]: {
    apiKey: 'D5B3BFA8487E20870AE97CA31557F906',
    stores: ['gymbeamsk', 'gymbeamcz'],
  },
  [TrackerId.SpeedCurve]: {
    apiKey: '4342503144', //it's actually id
    stores: [
      /*
      STORE_CODE_RECORD.gymbeambg.storeCode,
      STORE_CODE_RECORD.gymbeamcom.storeCode,
      STORE_CODE_RECORD.gymbeamcz.storeCode,
      STORE_CODE_RECORD.gymbeamgr.storeCode,
      STORE_CODE_RECORD.gymbeamhr.storeCode,
      STORE_CODE_RECORD.gymbeamhu.storeCode,
      STORE_CODE_RECORD.gymbeamit.storeCode,
      STORE_CODE_RECORD.gymbeampl.storeCode,
      STORE_CODE_RECORD.gymbeamro.storeCode,
      STORE_CODE_RECORD.gymbeamrs.storeCode,
      STORE_CODE_RECORD.gymbeamsi.storeCode,
      STORE_CODE_RECORD.ua.storeCode,
      STORE_CODE_RECORD.ru.storeCode,
      STORE_CODE_RECORD.gymbeamsk.storeCode,
      STORE_CODE_RECORD.gymbeamba.storeCode,
      STORE_CODE_RECORD.gymbeamde.storeCode,*/
    ],
  },
  [TrackerId.TrustPilot]: {
    stores: [
      STORE_CODE_RECORD.gymbeamde.storeCode,
      STORE_CODE_RECORD.gymbeamit.storeCode,
    ],
    scriptSrc:
      'https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js',
  },
}

export function isTrackerEnabled(
  trackerId: TrackerId,
  store: StoreCodeType,
): boolean {
  return trackingConfig[trackerId].stores.includes(store)
}
