import React, { FC } from 'react'

import { MobileNavigationItem } from './mobile-navigation-item'
import { HeaderNavItem } from '../navigation-types'

interface MobileNavigationItemsProps {
  items?: HeaderNavItem[]
  level?: number
}

export const MobileNavigationItems: FC<MobileNavigationItemsProps> = (
  props,
) => {
  return (
    <div>
      {props?.items?.map((item) => (
        <MobileNavigationItem key={item.href} {...item} level={props.level} />
      ))}
    </div>
  )
}
