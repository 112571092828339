function getTime(expirationDate?: string): {
  currentTime: number
  expirationTime?: number
} {
  const currentTime = new Date().getTime()

  try {
    const expirationTime = expirationDate ? new Date(expirationDate) : undefined

    if (!expirationTime || expirationTime.toString() === 'Invalid Date') {
      throw new Error('Invalid Date')
    }

    return {
      currentTime,
      expirationTime: expirationTime.getTime(),
    }
  } catch {
    return {
      currentTime,
      expirationTime: undefined,
    }
  }
}

export function getRibbonShown(expirationDate?: string) {
  const { currentTime, expirationTime } = getTime(expirationDate)

  if (!expirationTime) {
    return true
  }

  return expirationTime > currentTime
}
