import { PropsWithChildren } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { AlertIcon, CheckIcon } from '../icons/svg'
import { Container } from '../container'
import { selectors } from '@/common/constants/selectors-constants'

const variants = cva('py-2.5 pr-5 pl-3 mb-2 flex', {
  variants: {
    severity: {
      success: [
        'bg-alert-success-bg-color',
        'text-alert-success-text-color',
        'fill-alert-success-fill-color',
      ],
      warning: [
        'bg-alert-warning-bg-color',
        'text-alert-warning-text-color',
        'fill-alert-warning-fill-color',
      ],
      error: [
        'bg-alert-error-bg-color',
        'text-alert-error-text-color',
        'fill-alert-error-fill-color',
      ],
    },
  },
  defaultVariants: {
    severity: 'warning',
  },
})

export type AlertProps = PropsWithChildren<{
  severity: 'success' | 'warning' | 'error'
  className?: string
}>

export function Alert({
  severity,
  className,
  children,
}: AlertProps): JSX.Element {
  const Icon = severity === 'success' ? CheckIcon : AlertIcon

  return (
    <div className={twMerge(variants({ severity, className }))}>
      <Container>
        <div className="flex" data-test={selectors.common.alert}>
          <div className="pr-1">
            <Icon className="w-4 h-4 inline-flex mr-2 mt-1" />
          </div>
          <div>{children}</div>
        </div>
      </Container>
    </div>
  )
}
