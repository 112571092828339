'use client'

import { useEffect } from 'react'

export const CertifiedShopsBadge = ({ code }: { code: string }) => {
  useEffect(() => {
    const script = document.createElement('script')

    script.innerHTML = code
    document.body.appendChild(script)
    return () => {
      document.body.removeChild(script)
    }
  }, [code])

  return null
}
