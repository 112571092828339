'use client'

import { PropsWithChildren } from 'react'

import { useWindowSize } from '@/common/hooks/use-window-size'
import { BreakpointValues } from '@/common/hooks/use-browser-media-query'

interface DesktopOnlyComponentProps extends PropsWithChildren {
  isServerMobile?: boolean
}

export function DesktopOnlyComponent({
  children,
  isServerMobile,
}: DesktopOnlyComponentProps) {
  const { width } = useWindowSize()

  if (width) {
    return width >= BreakpointValues.md ? children : null
  }

  if (isServerMobile) {
    return null
  }

  return children
}
