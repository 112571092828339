import React from 'react'

import { HeaderDesktopNavigationPopup } from './header-desktop-navigation-popup'
import { useIsActiveCategory } from '@/components/header/components/utils/index'
import { HeaderNavItem } from '@/components/header/components/header-navigation/navigation-types'
import CategoryText from '@/components/header/components/header-navigation/desktop/header-desktop-navigation-text'
import { selectors } from '@/common/constants/selectors-constants'
import { Link } from '@/components/link/link'

interface HeaderDesktopNavigationProps {
  items?: HeaderNavItem[]
}

export const HeaderDesktopNavigation = ({
  items,
}: HeaderDesktopNavigationProps) => {
  const { isActiveCategory } = useIsActiveCategory()

  return (
    <ul
      data-test={selectors.common.header.menu}
      id="desktop-nav"
      className="relative hidden md:flex md:flex-wrap w-full mt-3"
    >
      {items?.map((item: HeaderNavItem, index) => (
        <li key={item.href} className="group">
          <Link
            className={`no-underline hover:no-underline leading-[53px] h-full py-4 px-[15px] ${
              index === 0 && 'pl-0'
            }`}
            href={item.href}
            isExternal
          >
            <CategoryText item={item} />
          </Link>

          <HeaderDesktopNavigationPopup
            items={item.items}
            isActive={isActiveCategory(item)}
          />
        </li>
      ))}
    </ul>
  )
}
