import { useEffect } from 'react'

type Props = {
  enabled: boolean
  onArrowDown: () => void
  onArrowUp: () => void
  onEnter: (e: KeyboardEvent) => void
  onEsc: () => void
}

/**
 * Custom hook that listens for keyboard events when the "enabled" flag is true
 * - https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/combobox_role#keyboard_interactions
 */
export function useKeyboardNav({
  enabled,
  onArrowDown,
  onArrowUp,
  onEnter,
  onEsc,
}: Props) {
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      switch (e.code) {
        case 'ArrowDown':
          onArrowDown()
          break
        case 'ArrowUp':
          onArrowUp()
          break
        case 'Enter':
          onEnter(e)
          break
        case 'Escape':
          onEsc()
          break
        default:
          break
      }
    }

    if (enabled) {
      document.addEventListener('keydown', handleKeyDown)
    }

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
    }
  }, [enabled, onArrowDown, onArrowUp, onEnter, onEsc])
}
