import React, { useState, RefObject, useEffect, FC, useCallback } from 'react'
import { createPortal } from 'react-dom'
import { useTranslations } from 'next-intl'

export interface RibbonProps {
  date: string
  wrapperRef: RefObject<HTMLElement>
}

export const RibbonCountdown: FC<RibbonProps> = (props) => {
  const { date, wrapperRef } = props

  const dateTime = new Date(date).getTime()

  const element = wrapperRef?.current?.querySelector(
    'span[data-ribbon="placeholder"]',
  )
  const t = useTranslations('Header')

  const calculateTimeDiff = useCallback(() => {
    if (!isFinite(dateTime)) {
      return null
    }

    const diff = (dateTime - new Date().getTime()) / 1000 // Difference in seconds
    const hours = Math.floor(diff / (60 * 60))
    const minutes = Math.floor(diff / 60) % 60
    const seconds = Math.floor(diff) % 60

    return { hours, minutes, seconds }
  }, [dateTime])

  const [diff, setDiff] = useState(calculateTimeDiff())

  useEffect(() => {
    setDiff(calculateTimeDiff())
    const interval = setInterval(() => {
      setDiff(calculateTimeDiff())
    }, 1000)
    return () => clearInterval(interval)
  }, [calculateTimeDiff])

  if (!element || diff === null) {
    return <></>
  }

  return (
    <>
      {createPortal(
        <span suppressHydrationWarning>
          {diff.hours} {t('ribbonHours')} {diff.minutes} {t('ribbonMinutes')}{' '}
          {diff.seconds} {t('ribbonSeconds')}
        </span>,
        element,
      )}
    </>
  )
}
