import { ButtonHTMLAttributes, forwardRef, ReactNode } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { UIAlign, UISize } from '@/common/types/ui-types'
import { Loader } from '../loader'

const variants = cva(
  [
    'text-white',
    'font-bold',
    'leading-normal',
    'items-center',
    'justify-center',
    'uppercase',
    'whitespace-nowrap',
    'truncate',
    'transition-colors duration-500 ease-in-out',
  ],
  {
    variants: {
      variant: {
        primary: ['bg-primary'],
        secondary: ['bg-secondary', 'hover:bg-secondary-strong'],
        success: ['bg-success', 'hover:bg-success-strong'],
        ghost: ['bg-none', 'text-grey-500', 'hover:bg-grey-100'],
        light: [
          'border-2',
          'border-secondary',
          'text-xs',
          'text-secondary',
          'hover:bg-secondary',
          'hover:text-white',
        ],
        shadow: [
          'bg-shadow',
          'hover:bg-secondary',
          'transition ease-in-out duration-500',
        ],
        tertiary: [
          'bg-shadow',
          'hover:bg-grey-400',
          'transition ease-in-out duration-500',
        ],
        text: [
          '[text-transform:unset]',
          'text-base',
          'font-bold',
          'text-gray',
          'underline',
          'hover:[color:#555]',
        ],
        outline: [
          'bg-transparent text-secondary',
          'border border-solid border-secondary',
          'hover:bg-secondary hover:text-white',
          'transition ease-in-out duration-500',
        ],
      },
      size: {
        xs: ['px-3', 'py-1', 'font-semibold'],
        sm: ['px-6', 'py-2', 'font-semibold'],
        base: ['px-14', 'h-12'],
        lg: ['px-14', 'h-16'],
        xl: ['px-14', 'h-18'],
      },
      disabled: {
        true: ['opacity-50', 'cursor-not-allowed', 'hover:disabled:bg'],
      },
      fullWidth: {
        true: ['w-full flex flex-1'],
      },
      align: {
        left: ['justify-start'],
        center: ['justify-center'],
        right: ['justify-end'],
        between: ['justify-between'],
      },
    },

    compoundVariants: [],

    defaultVariants: {
      variant: 'primary',
      size: 'base',
      align: 'center',
    },
  },
)

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  variant?:
    | 'primary'
    | 'secondary'
    | 'tertiary'
    | 'success'
    | 'ghost'
    | 'shadow'
    | 'text'
    | 'light'
    | 'outline'
  align?: UIAlign
  size?: UISize
  loader?: boolean | ReactNode
  disabled?: boolean
  fullWidth?: boolean
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  const { children, loader, className, ...rest } = props

  return (
    <button
      ref={ref}
      className={twMerge(variants({ ...rest, className }))}
      tabIndex={0}
      {...rest}
    >
      <Loader align={rest.align} loader={loader}>
        {children}
      </Loader>
    </button>
  )
})

Button.displayName = 'Button'
export { Button }
