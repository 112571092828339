import { useEffect } from 'react'

import type { MutableRefObject } from 'react'

type Props = {
  ref: MutableRefObject<HTMLElement | null>
  enabled: boolean
  onClickOutside: () => void
}

/**
 * Custom hook that listens for clicks outside of a specified "ref" element.
 * When the "enabled" flag is true, it starts listening to "mousedown" event.
 * If a click occurs outside of the element, the "onClickOutside" function is invoked.
 */
export function useClickOutside({ ref, enabled, onClickOutside }: Props) {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      if (ref.current && !ref.current.contains(e.target as Node)) {
        onClickOutside()
      }
    }

    if (enabled) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [onClickOutside, enabled, ref])
}
