import { WithClassName } from '@/common/types/utility'
import { cn } from '@/common/utils/lib'

type OverlayProps = {
  onClose?: () => void
}

export const Overlay = ({
  onClose,
  className,
}: WithClassName<OverlayProps>) => (
  <div
    onClick={onClose}
    className={cn('fixed inset-0 bg-black bg-opacity-50', className)}
  />
)
