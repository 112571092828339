'use client'

import { useTranslations } from 'next-intl'

import { Flex } from '@/components/flex'
import { IconButton } from '@/components/icon-button'
import { HamburgerIcon } from '@/components/icons/svg'
import { useHeaderContext } from './header-context'

export interface HeaderHamburgerProps {
  className?: string
}

export const HeaderHamburger = ({ className }: HeaderHamburgerProps) => {
  const t = useTranslations('Header')
  const {
    state: { isMenuOpen },
    actions: { setIsMenuOpen },
  } = useHeaderContext()

  return (
    <Flex className={className}>
      <IconButton
        onClick={() => setIsMenuOpen(!isMenuOpen)}
        variant="ghost"
        aria-label={t('menu')}
      >
        <HamburgerIcon className="h-6 w-6" />
      </IconButton>
    </Flex>
  )
}
