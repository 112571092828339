import { useTranslations } from 'next-intl'

import { useAuthContext } from '@/providers'
import { LOGOUT_PATH, MY_ACCOUNT_PATH, MY_ORDERS_PATH } from '../../paths'
import { Link } from '@/components/link/link'

export const CustomerPopupMenuItems = () => {
  const t = useTranslations('Header')
  const { logout } = useAuthContext()

  const ghostLink = 'flex h-12 px-2 w-fit items-center hover:bg-grey-100'

  return (
    <>
      <Link href={MY_ACCOUNT_PATH} className={`${ghostLink} w-full`} isExternal>
        {t('myAccount')}
      </Link>
      <Link href={MY_ORDERS_PATH} className={`${ghostLink} w-full`} isExternal>
        {t('myOrders')}
      </Link>
      <Link
        href={LOGOUT_PATH}
        onClick={logout}
        className={`${ghostLink} font-bold w-full`}
        isExternal
      >
        {t('logOut')}
      </Link>
    </>
  )
}
