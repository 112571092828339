import * as React from 'react'

import type { SVGProps } from 'react'

const SvgHr = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 480"
    width="1em"
    height="1em"
    {...props}
  >
    <path fill="#171796" d="M0 0h640v480H0z" />
    <path fill="#fff" d="M0 0h640v320H0z" />
    <path
      fill="red"
      d="M0 0h640v160H0zm320 364.1c51.3 0 93.3-42 93.3-93.3V160H226.7v110.8c0 51.4 42 93.4 93.3 93.4z"
    />
    <path
      fill="#fff"
      d="M320 362.6a91.8 91.8 0 0 0 91.6-91.5V161.8H228.5V271a91.8 91.8 0 0 0 91.5 91.5z"
    />
    <g fill="red">
      <path d="M267.1 165.2H232v38.7h35.2zm0 77.4h35.3v-38.7H267zM232 270.9c0 3.5.2 7 .6 10.4h34.6v-38.7H232v28.3zm105.7-28.3h-35.2v38.7h35.2zm0 77.4h35.2v-38.7h-35.2zm35.2 21.2A89.2 89.2 0 0 0 393 320h-20v21.2zM267.1 320h35.3v-38.7H267zm-20.1 0a89.2 89.2 0 0 0 20.1 21.2V320H247zm79.1 38.7c4-.3 7.7-.8 11.5-1.6V320h-35.2v37.1a87 87 0 0 0 11.4 1.6c4.1.3 8.2.2 12.3 0z" />
      <path d="M407.4 281.3c.4-3.4.6-6.9.6-10.4v-28.3h-35.2v38.7h34.6zm-69.8-38.7h35.2v-38.7h-35.2zm0-77.4h-35.2v38.7h35.2zm70.4 38.7v-38.7h-35.2v38.7z" />
    </g>
    <path
      fill="#fff"
      d="m410 158.8 21.8-49.5-16.6-26.8-27.6 10.2-19.4-22.2-25.4 14.7L320 66.5l-22.7 18.6-25.5-14.6-19.4 22.2-27.6-10.3-16.6 27 21.8 49.4a217.8 217.8 0 0 1 90-19.3c32 0 62.5 6.9 90 19.3z"
    />
    <path
      fill="#0093dd"
      d="m253 94.8-27.4-10-15.3 24.7 5.9 13.3 14.8 33.7a219.7 219.7 0 0 1 34.6-12L253 94.8z"
    />
    <path
      fill="#171796"
      d="M297.5 87.4 272.1 73 253 94.9l12.6 49.6a219.8 219.8 0 0 1 36.1-6l-4.2-51.1z"
    />
    <path
      fill="red"
      d="M262.5 132.2a232.3 232.3 0 0 1 38.2-6.4l-1-12.9a245.6 245.6 0 0 0-40.4 6.7l3.2 12.6zm-6.3-25a258 258 0 0 1 42.4-7l-1.1-12.7a270.7 270.7 0 0 0-44.5 7.4l3.2 12.3z"
    />
    <path
      fill="#0093dd"
      d="m342.689 87.43-.055.03-22.555-18.472-22.508 18.434 4.238 51.097a221.902 221.902 0 0 1 18.27-.763c6.181 0 12.3.268 18.353.77l4.254-51.097z"
    />
    <path
      fill="#171796"
      d="m387.2 94.9-19.3-22-25.2 14.5-4.3 51.1a219.7 219.7 0 0 1 36.2 6L387.2 95z"
    />
    <path
      fill="#0093dd"
      d="m409 156.5 20.8-47-15.3-24.7-27.3 10-12.7 49.8a219.4 219.4 0 0 1 34.5 11.9z"
    />
  </svg>
)
export default SvgHr
