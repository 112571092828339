'use client'

import { useState, useEffect } from 'react'

import type { PropsWithChildren } from 'react'

export function ClientOnlyComponent({ children }: PropsWithChildren) {
  const [isFirstRender, setIsFirstRender] = useState(true)

  useEffect(() => {
    setIsFirstRender(false)

    return () => {
      setIsFirstRender(true)
    }
  }, [])

  if (isFirstRender) {
    return null
  }

  return children
}
