import { convertAbsoluteUrlToRelative } from '@/common/utils/url-utils'
import { HeaderNavItem } from '../header-types'

export const isActiveChild = (
  pathname: string,
  parentItems: HeaderNavItem[] = [],
): boolean => {
  return parentItems.some(
    ({ href, items }) =>
      pathname === convertAbsoluteUrlToRelative(new URL(href)) ||
      isActiveChild(pathname, items),
  )
}
