import React, { HTMLAttributes, ReactNode } from 'react'
import { twMerge } from 'tailwind-merge'

import { useClickAway } from '@/common/hooks/use-click-away'
import { PopupContent } from './popup-content'

interface PopupRenderProps {
  isOpen: boolean
  onClose: () => void
}

/**
 * Headless component that provides a popup
 * functionality to its children.
 */
export type PopupProps = {
  // content of the popup
  content?: ReactNode
  // trigger of the popup
  children: (childrenProps: PopupRenderProps) => ReactNode
  // open on hover
  openOnHover?: boolean
} & Partial<Omit<HTMLAttributes<HTMLDivElement>, 'children' | 'content'>>

export const Popup = ({
  children,
  openOnHover,
  content,
  className,
  ...rest
}: PopupProps) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const wrapperRef = React.useRef<HTMLDivElement>(null)

  // handlers
  const onClick = () => {
    if (!openOnHover) {
      setIsOpen(!isOpen)
    }
  }
  const onMouseEnter = () => {
    if (openOnHover) {
      setIsOpen(true)
    }
  }

  const onClose = () => {
    setIsOpen(false)
  }

  // effects
  useClickAway(wrapperRef, () => setIsOpen(false))

  // exposed props
  const popupProps: PopupRenderProps = {
    isOpen,
    onClose,
  }

  const parentClientRect = wrapperRef.current?.getBoundingClientRect()

  return (
    <div
      {...rest}
      ref={wrapperRef}
      onClick={onClick}
      onMouseEnter={onMouseEnter}
      onMouseLeave={() => openOnHover && onClose()}
      className={twMerge('relative', className)}
    >
      {children(popupProps)}
      {isOpen && content && (
        <PopupContent parentClientRect={parentClientRect}>
          {content}
        </PopupContent>
      )}
    </div>
  )
}
