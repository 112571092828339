import React, { PropsWithChildren } from 'react'

import { cn } from '@/common/utils/lib'
import { WithClassName } from '@/common/types/utility'

type Props = {
  isOpen?: boolean
  enterAnimation?: string
  leaveAnimation?: string
  withAnimation?: boolean
}

type ModalContentProps = PropsWithChildren<WithClassName<Props>>

export const ModalContent = ({
  isOpen,
  children,
  className,
  leaveAnimation = 'animate-slideUp',
  enterAnimation = 'animate-slideDownStraight',
  withAnimation = true,
}: ModalContentProps) => {
  return (
    <div
      className={cn(
        'w-[320px] relative shadow-lg bg-white mx-auto mt-[50px] p-6',
        withAnimation && (isOpen ? enterAnimation : leaveAnimation),
        className,
      )}
    >
      {children}
    </div>
  )
}
