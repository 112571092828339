'use client'

import { useEffect, useRef } from 'react'

import { formatLocale } from '@/common/utils/format-locale'
import { STORE_CODE_RECORD } from '@/common/constants'
import { StoreCodeType } from '@/common/types'

const TRUSTPILOT_TEMPLATE_ID = '53aa8807dec7e10d38f59f32'
const TRUSTPILOT_DATA = {
  gymbeamde: {
    href: 'https://de.trustpilot.com/review/gymbeam.de',
    businessUnitId: '6358359d93221db72b9fb209',
  },
  gymbeamit: {
    href: 'https://it.trustpilot.com/review/gymbeam.it',
    businessUnitId: '62ce9b145dc1725db387ec48',
  },
}

type Props = {
  storeCode: string
}

export const TrustpilotBadge = ({ storeCode }: Props) => {
  const ref = useRef(null)
  useEffect(() => {
    const loadTrustBox = () => {
      if (window.Trustpilot) {
        window.Trustpilot.loadFromElement(ref.current, true)
      } else {
        // If Trustpilot is not yet loaded, check again after 100 milliseconds
        setTimeout(loadTrustBox, 500)
      }
    }

    loadTrustBox()
  }, [])

  const { locale } = STORE_CODE_RECORD[storeCode as StoreCodeType] ?? {}
  const formattedLocale = formatLocale(locale)

  return (
    <div
      ref={ref}
      className="trustpilot-widget"
      data-locale={formattedLocale}
      data-template-id={TRUSTPILOT_TEMPLATE_ID}
      data-businessunit-id={TRUSTPILOT_DATA[storeCode]?.businessUnitId}
      data-style-height="150px"
      data-style-width="100%"
      data-theme="dark"
    >
      <a href={TRUSTPILOT_DATA[storeCode]?.href} target="_blank" rel="noopener">
        Trustpilot
      </a>
    </div>
  )
}
