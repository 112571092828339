import { useEffect, useState } from 'react'

type UseIsModalVisibleProps = {
  isOpen?: boolean
  delay?: number
}
/**
 * @param delay should be 50ms less than animation duration to compensate for
 * rerender time and prevent flickering.
 */
export const useIsModalVisible = ({
  delay = 200,
  isOpen = false,
}: UseIsModalVisibleProps) => {
  const [isVisible, setIsVisible] = useState(isOpen)

  useEffect(() => {
    if (isOpen) {
      setIsVisible(true)
    } else {
      const timer = setTimeout(() => {
        setIsVisible(false)
      }, delay)

      return () => clearTimeout(timer)
    }
  }, [isOpen, delay])

  return isVisible
}
