'use client'

import { useEffect, useRef } from 'react'

export default function CookieSettingsRenderer() {
  const ref = useRef<HTMLElement | null>(null)

  useEffect(() => {
    const domNode = document.getElementById('cookie-settings')
    if (domNode?.title) {
      ref.current = domNode
      ref.current.onclick = () => window?.Cookiebot?.renew()
      ref.current.textContent = ref.current.title
    }
  }, [])

  return null
}
