'use client'

import { useRef } from 'react'

import { RibbonCountdown } from './ribbon-countdown'
import { gtmTracker } from '@/common/services/tracking/google/gtm-tracker'
import { getRibbonShown } from '../../utils/get-show-ribbon'

export type RibbonBarProps = {
  enabled: boolean
  message: string
  backgroundColor?: string
  fontColor?: string
  date?: string
}

export function RibbonBar({
  enabled,
  message,
  backgroundColor,
  fontColor,
  date,
}: RibbonBarProps) {
  const wrapperRef = useRef<HTMLDivElement>(null)

  if (!enabled || !getRibbonShown(date)) {
    return null
  }

  const html = message.replace(
    '%countdown',
    '<span data-ribbon="placeholder"></span>',
  )

  return (
    <div
      ref={wrapperRef}
      className="w-full p-3 text-center text-ribbon-default-font-color bg-ribbon-default-bg-color"
      style={{ backgroundColor: backgroundColor }}
      onClick={() => gtmTracker.trackRibbonClick()}
    >
      <span
        dangerouslySetInnerHTML={{ __html: html }}
        className="text-white [&>a]:underline [&>b>a]:underline"
        style={{ color: fontColor }}
      />
      {date && <RibbonCountdown date={date} wrapperRef={wrapperRef} />}
    </div>
  )
}
