'use client'

import { RefObject, useEffect } from 'react'

export const useClickAway = (
  ref: RefObject<HTMLElement>,
  callback: () => void,
): void => {
  useEffect(() => {
    const handleClickOrTouchOutside = (event: MouseEvent | TouchEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }

    // Bind the event listener for both mouse and touch events
    document.addEventListener('mousedown', handleClickOrTouchOutside)
    document.addEventListener('touchstart', handleClickOrTouchOutside)

    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOrTouchOutside)
      document.removeEventListener('touchstart', handleClickOrTouchOutside)
    }
  }, [ref, callback])
}
