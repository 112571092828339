'use client'

import { useCallback } from 'react'
import { usePathname } from 'next/navigation'

import { isActiveChild } from './is-active-child'
import { HeaderNavItem } from '../header-types'
import { convertAbsoluteUrlToRelative } from '@/common/utils/url-utils'

export const isAcitveCategoryByUrl = (pathname, href: string) => {
  return pathname === convertAbsoluteUrlToRelative(new URL(href))
}

export const useIsActiveCategory = () => {
  const pathname = usePathname()

  const isActiveCategory = useCallback(
    ({ href, level, items }: HeaderNavItem) =>
      isAcitveCategoryByUrl(pathname, href) ||
      isActiveChild(pathname, level === 0 ? items : []),
    [pathname],
  )

  return { isActiveCategory, pathname, isAcitveCategoryByUrl }
}
