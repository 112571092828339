'use client'

import React, { useEffect, useState } from 'react'

import { HeaderDesktopNavigation } from './desktop/header-desktop-navigation'
import { HeaderMobileNavigation } from './mobile/mobile-navigation-component'
import { HeaderNavItem } from './navigation-types'
import { useBrowserMediaQuery } from '@/common/hooks/use-browser-media-query'

interface HeaderNavigationProps {
  className?: string
  items?: HeaderNavItem[]
  isServerMobile?: boolean
}

export const HeaderNavigation = ({
  className,
  items,
  isServerMobile,
}: HeaderNavigationProps) => {
  const isMd = useBrowserMediaQuery({ breakpoint: 'md' })

  /**
   * INFO: for CSR = true, for SSR and disabled JavaScript = FALSE
   */
  const [isJavaScriptEnabled, setJavaScriptEnabled] = useState(false)

  useEffect(() => {
    setJavaScriptEnabled(typeof window !== 'undefined')
  }, [])

  const navItems = items || []
  const isMobile = isMd ?? isServerMobile

  return (
    <nav className={className}>
      {isJavaScriptEnabled && isMobile ? (
        <HeaderMobileNavigation items={navItems} />
      ) : (
        <HeaderDesktopNavigation items={navItems} />
      )}
    </nav>
  )
}
