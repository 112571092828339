import React, { FC } from 'react'
import { twMerge } from 'tailwind-merge'

import { Text } from '@/components/text'
import { useIsActiveCategory } from '../../utils'
import { HeaderNavItem } from '../navigation-types'
import { Link } from '@/components/link/link'

interface HeaderDesktopNavigationItemProps {
  item: HeaderNavItem
  isNested?: boolean
}

const SUBSECTION_LEVEL = 3

const isRootLevelOfSubsection = (level?: number) => level === SUBSECTION_LEVEL

export const HeaderDesktopNavigationItem: FC<
  HeaderDesktopNavigationItemProps
> = ({ item, isNested = false }: HeaderDesktopNavigationItemProps) => {
  const { isActiveCategory } = useIsActiveCategory()

  return (
    <li
      className={twMerge(
        !isNested && 'w-1/4 float-left mb-[15px] min-h-[155px]',
      )}
    >
      <Link className="hover:text-secondary" href={item.href} isExternal>
        <Text
          size="xs"
          weight={isRootLevelOfSubsection(item.level) ? 'bold' : 'normal'}
          color={isActiveCategory(item) ? 'active' : undefined}
          className="hover:text-secondary"
        >
          {item.label}
        </Text>
      </Link>
      <ul className={twMerge(isNested && 'pl-2')}>
        {item.items?.map((item: HeaderNavItem) => (
          <HeaderDesktopNavigationItem key={item.href} item={item} isNested />
        ))}
      </ul>
    </li>
  )
}
