'use client'
import { FC, Fragment, HTMLAttributes, JSX } from 'react'
import { useTranslations } from 'next-intl'

import { selectors } from '@/common/constants/selectors-constants'
import { formatPrice } from '@/common/utils/price-utils'
import { Currency as CurrencyType } from '@/common/types/price-types'
import { useStoreContext } from '@/providers'

interface CurrencyProps extends HTMLAttributes<HTMLSpanElement> {
  component?: keyof JSX.IntrinsicElements
  currency?: CurrencyType | null
  price?: number | null
  isPriceRange?: boolean
}

const Currency: FC<CurrencyProps> = (props) => {
  const { component, className, role, currency, price, isPriceRange } = props
  const Component = component || 'span'
  const t = useTranslations('ProductBox')
  const { storeConfig, storeCode } = useStoreContext()
  const dualCurrency = {
    rate: storeConfig.checkoutCurrency?.rate,
    format: storeConfig.checkoutCurrency?.format,
  }
  const formattedPrice = formatPrice({ storeCode, currency, price: price ?? 0 })
  const formattedDualPrice =
    dualCurrency?.rate && dualCurrency?.format
      ? formatPrice({
          storeCode,
          price: (price ?? 0) * dualCurrency.rate ?? 0,
          currencyFormat: dualCurrency.format,
          dualCurrency: true,
        })
      : undefined

  return (
    <Component role={role} className={className}>
      {price !== undefined ? (
        isPriceRange ? (
          t.rich('price', {
            price: () => (
              <Fragment>
                {formattedPrice}
                {dualCurrency?.format && formattedDualPrice && (
                  <span className="text-grey-300 ml-1 text-sm font-normal">
                    ~({formattedDualPrice})
                  </span>
                )}
              </Fragment>
            ),
            from: (chunks) => (
              <span
                className="font-normal pr-1"
                data-test={selectors.HP.bestsellersPrice}
              >
                {chunks}
              </span>
            ),
          })
        ) : (
          <Fragment>
            <span data-test={selectors.HP.bestsellersPrice}>
              {formattedPrice}
            </span>

            {dualCurrency?.format && formattedDualPrice && (
              <span className="text-grey-300 ml-1 text-sm font-normal">
                ~({formattedDualPrice})
              </span>
            )}
          </Fragment>
        )
      ) : (
        <span />
      )}
    </Component>
  )
}

Currency.displayName = 'Currency'
export { Currency }
