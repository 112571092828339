'use client'

import React, { useEffect, useRef } from 'react'

import { Flex } from '@/components/flex'
import { useProductUiContext } from '@/providers'

export const HeaderWrapper = ({ children, ...props }) => {
  const headerRef = useRef<HTMLDivElement>(null)
  const { setHeaderRefCallback } = useProductUiContext()

  useEffect(() => {
    setHeaderRefCallback(headerRef)
  }, [headerRef, setHeaderRefCallback])

  return (
    <Flex ref={headerRef} {...props}>
      {children}
    </Flex>
  )
}
