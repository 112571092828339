import React, { FC, HTMLAttributes } from 'react'
import { cva } from 'class-variance-authority'
import { twMerge } from 'tailwind-merge'

import { UIAlign, UISize } from '@/common/types/ui-types'

const variants = cva(['font-normal'], {
  variants: {
    variant: {
      normal: ['font-normal'],
    },
    size: {
      xs: ['text-xs'],
      sm: ['text-sm'],
      base: ['text-base'],
      lg: ['text-lg'],
      xl: ['text-xl'],
    },
    weight: {
      light: ['font-light'],
      normal: ['font-normal'],
      semibold: ['font-semibold'],
      bold: ['font-bold'],
    },
    color: {
      grey500: ['text-grey-500'],
      black: ['text-black'],
      active: ['text-secondary'],
      red: ['text-secondary'],
    },
    align: {
      left: ['text-left'],
      center: ['text-center'],
      right: ['text-right'],
      between: [],
    },
    case: {
      upper: ['uppercase'],
      lower: ['lowercase'],
      normal: ['normal-case'],
    },
    decoration: {
      underline: ['underline'],
      strikethrough: ['line-through'],
      none: ['no-underline'],
    },
  },
  defaultVariants: {
    variant: 'normal',
    size: 'base',
    weight: 'normal',
    align: 'left',
    case: 'normal',
    color: 'black',
    decoration: 'none',
  },
})

interface TextProps extends HTMLAttributes<HTMLSpanElement> {
  variant?: 'normal'
  size?: UISize
  weight?: 'light' | 'normal' | 'semibold' | 'bold'
  color?: 'active' | 'grey500' | 'black' | 'red'
  case?: 'upper' | 'lower' | 'normal'
  align?: UIAlign
  decoration?: 'underline' | 'strikethrough' | 'none'
}

const Text: FC<TextProps> = (props) => {
  const { children, variant, className, ...rest } = props

  return (
    <span className={twMerge(variants({ variant, ...rest }), className)}>
      {children}
    </span>
  )
}

Text.displayName = 'Text'
export { Text }
