import { selectors } from '@/common/constants/selectors-constants'

type Props = {
  label: string
  query: string
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
}

export function AutocompletePopupItemHighlighted({ label, query }: Props) {
  const parts = label.split(new RegExp(`(${escapeRegExp(query)})`, 'gi'))
  const lowerCaseQuery = query?.toLowerCase()

  return (
    <span
      className="flex-1"
      data-test={selectors.common.autocomplete.highlightedText}
    >
      {parts?.map((part, i) => {
        const Tag = lowerCaseQuery === part.toLowerCase() ? 'strong' : 'span'
        return <Tag key={i}>{part}</Tag>
      })}
    </span>
  )
}
