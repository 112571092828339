'use client'

import { PropsWithChildren } from 'react'

import { useWindowSize } from '@/common/hooks/use-window-size'
import { BreakpointValues } from '@/common/hooks/use-browser-media-query'

interface MobileOnlyComponentProps extends PropsWithChildren {
  isServerMobile?: boolean
}

export function MobileOnlyComponent({
  children,
  isServerMobile,
}: MobileOnlyComponentProps) {
  const { width } = useWindowSize()

  if (width) {
    return width < BreakpointValues.md ? children : null
  }

  if (isServerMobile) {
    return children
  }

  return null
}
