'use client'

import NextLink, { LinkProps } from 'next/link'
import { forwardRef } from 'react'

import type { HTMLProps, PropsWithChildren, Ref } from 'react'
import { useStoreContext } from '@/providers/store/store-context'
import { addSlashToUaHomepagePathname, getUrl } from '@/common/utils/url-utils'

type Props = {
  href: URL | string
  id?: string
  title?: string
  className?: string
  keepUrl?: boolean
  isExternal?: boolean
  /**
   * @deprecated don't use "baseUrl"
   */
  baseUrl?: string // TODO: cleanup baseUrl prop drilling
} & LinkProps &
  Omit<HTMLProps<HTMLAnchorElement>, 'href'>

/**
 * - "keepUrl" is set -> `use "href" as is (exception for "ua" homepage)`
 * - "keepUrl" is not set -> `use "baseUrl" to generate origin for "href"`
 * - "isExternal" is set -> `redirection to magento pages - use "a" tag`
 * - "isExternal" is not set -> `in react app navigation - use NextLink`
 *
 * TODO:
 * - cleanup unused utils from "@/common/utils/url-utils"
 * - verify each Link instance used in repo that is correctly called
 * - verify useRouter usage
 */
export const Link = forwardRef(function Link(
  {
    href,
    keepUrl = false,
    isExternal = false,
    baseUrl: _baseUrl,
    ...props
  }: Props & PropsWithChildren,
  ref: Ref<HTMLAnchorElement> | undefined,
) {
  const {
    storeConfig: { baseUrl },
  } = useStoreContext()
  const url = keepUrl ? href : getUrl(baseUrl, href.toString())
  const urlWithSlashForUaPathname = addSlashToUaHomepagePathname(url)

  return isExternal ? (
    <a
      href={urlWithSlashForUaPathname.toString()}
      {...props}
      ref={ref}
      id="external"
    />
  ) : (
    <NextLink
      data-testid="link"
      prefetch={false}
      href={urlWithSlashForUaPathname}
      {...props}
      ref={ref}
    />
  )
})
