import React from 'react'
import { twMerge } from 'tailwind-merge'

import { HeaderDesktopNavigationItem } from './header-desktop-navigation-item'
import { HeaderNavItem } from '../navigation-types'

interface HeaderDesktopNavigationPopupProps {
  items?: HeaderNavItem[]
  isActive?: boolean
}

export const HeaderDesktopNavigationPopup = ({
  items,
  isActive,
}: HeaderDesktopNavigationPopupProps) => {
  return items?.length ? (
    <ul
      className={twMerge(
        'hidden group-hover:block',
        'absolute left-0 z-10',
        'w-full p-[12px] bg-white',
        'border-black border-[2px]',
        isActive && 'mt-[3px]',
      )}
    >
      {items.map((item: HeaderNavItem) => (
        <HeaderDesktopNavigationItem key={item.href} item={item} />
      ))}
    </ul>
  ) : null
}
