import { useEffect } from 'react'

type UsePressEscapeProps = {
  isOpen: boolean
  onPress: () => void
}

export const usePressEscape = ({ isOpen, onPress }: UsePressEscapeProps) => {
  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onPress()
      }
    }

    if (isOpen) {
      window.addEventListener('keydown', handleEscape)
    }

    return () => window.removeEventListener('keydown', handleEscape)
  }, [isOpen, onPress])
}
